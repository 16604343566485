#bootstrapoverride {
	overflow-x: hidden; }


#bootstrapoverride .body-wrapper {
	max-width: 1920px;
	margin: 0 auto; }


#bootstrapoverride .home-container {
	@include image-formating("../../../assets/img/home-desktop-bg.png");
	background-size: auto;
	background-position: 50% 65%;
	position: relative;
	padding-bottom: 60px;
	overflow: hidden;


	.video-container {
		position: relative;
		width: 100%;
		height: 650px;
		overflow: hidden;
		clip-path: polygon(0 0, 1920px 0, 1920px 75%, 0 100%);

		.video-wrapper {
			position: absolute {
		    height: 100%;
		    width: 100%;
		    overflow: hidden; }

			video {
			    min-width: 100%;
			    min-height: 100%; } }


		.video-mask {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 650px;
			opacity: .6;
			background: linear-gradient(to right, #a159c9 0%, #3023ae 25%, #3721ac 50%, #e0007a 75%, #e92f64 100%);
			clip-path: polygon(0 0, 1920px 0, 1920px 75%, 0 100%); } }


	.hero-container .hero-headings {
		position: absolute;
		top: 230px;
		left: 0;
		right: 0;
		width: 400px;
		margin: 0 auto;
		text-align: center;

		.innovex-logo img {
			width: 230px;
			height: 80px; }

		p {
			font-size: 18px;
			margin: 10px 0 5px;
			color: white;
			font-family: $primary-style;
			font-style: italic;
			text-shadow: $text-shadow2; }

		.gradient-btn {
			border: 3px solid #fecd19;
			color: #fecd19;
			background-color: transparent;

			&:hover,
			&:focus,
			&:active {
				background-color: rgba(21,17,55,.82); } } }


	p {
		font-family: $secondary-style; }


	.introduction-section {
		.pictures {
			position: relative;
			float: left;
			width: 590px;
			height: 525px;
			margin-right: 35px;

			div {
				position: absolute;

				&:nth-of-type(1) {
					top: 10px;
					right: 0;
					width: 285px;
					height: 185px;

					img {
						height: 185px; } }

				&:nth-of-type(2) {
					bottom: 0;
					right: 50px;
					width: 355px;
					height: 205px;

					img {
						height: 205px; } }

				&:nth-of-type(3) {
					top: 120px;
					left: 60px;
					width: 380px;
					height: 260px;
					box-shadow: $box-shadow;

					img {
						height: 260px; } } } }


		.introduction {
			float: left;
			width: calc(1200px - 660px);

			h3 {
				font-size: 40px;
				line-height: 51px;
				text-align: left;
				margin-bottom: 84px;

				&.yellow-skew:after {
					bottom: -45px;
					right: auto; } }

			p {
				margin-bottom: 14px;
				font-size: 18px;
				line-height: 28px; }

			.location {
				float: left;
				font-weight: 700;
				color: $pink;
				font-size: 18px;
				line-height: 28px;
				font-style: italic; }

			.address {
				display: inline-block;
				float: left;
				width: calc(100% - 80px); }


			.gradient-btn {
				margin: 180px 0 0 0; } } }


	.info-section {
		width: 880px;
		padding: 0;

		a {
			display: inline-block;
			float: left;
			width: 280px;
			height: 190px;

			&:nth-of-type(2) {
				margin: 0 20px; } }

		.inner-wrapper {
			position: relative;
			width: 100%;
			height: 190px;
			padding: 20px 0;
			text-align: center;
			background-color: white;
			border: 5px solid #4a4a4a;

			&:hover .border-top {
				width: calc(100% + 10px);
				transition: width .25s !important; }

			&:hover .border-right {
				height: 190px;
				transition: height .25s .25s !important; }

			&:hover .border-bottom {
				width: calc(100% + 9px); //for some reason sometimes overflows if set to 10px
				transition: width .25s !important; }

			&:hover .border-left {
				height: 190px;
				transition: height .25s .25s !important; }

			&:hover h3 {
				color: #e0007a; }


			img {
				width: 100px;
				height: 100px;
				margin-bottom: 10px; }

			h3 {
				margin: 0;
				font-weight: bold;
				font-style: italic;
				transition: color .25s; } }

		span {
			position: absolute; }

		.border-top {
			top: -5px;
			left: -5px;
			width: 0;
			height: 5px;
			transition: width .25s .25s;
			background: linear-gradient(to right, #3721ac 0%, #e0007a 70%, #e92f64 100%); }

		.border-right {
			top: -5px;
			right: -5px;
			width: 5px;
			height: 0;
			transition: height .25s;
			background: linear-gradient(to bottom, #e92f64 0%, #e0007a 30%, #3721ac 100%); }

		.border-bottom {
			bottom: -10px;
			left: calc(100% + 5px);
			width: 0;
			height: 5px;
			transition: width .25s .25s;
			transform: rotate(180deg);
			transform-origin: 0 0;
			background: linear-gradient(to right, #3721ac 0%, #3023ae 70%, #a159c9 100%); }

		.border-left {
			top: 185px;
			left: 0px;
			width: 5px;
			height: 0;
			transition: height .25s;
			transform: rotate(180deg);
			transform-origin: 0 0;
			background: linear-gradient(to top, #3721ac 0%, #3023ae 70%, #a159c9 100%); } }


	section.breaking-news-section {
		max-width: 1150px;

		.inner-wrapper {
			@include gradient-border(4px, top left);
			padding: 35px 45px;
			background-color: white; }


		.content-wrapper {
			position: relative;
			width: 325px;
			height: 410px;
			margin: 0 auto; }

		.content-wrapper>a {
			display: block;

			&:hover .see-more {
				font-weight: bold;
				text-decoration: underline; } }


		.img-center {
			height: 155px;
			margin-bottom: 10px;

			img {
				height: 155px; } }


		.text-wrapper {
			max-height: 180px;
			overflow: hidden; }

		.pink-date {
			margin-bottom: 10px; }

		.blue-title {
			margin: 0 0 10px 0; }

		.content {
			max-height: 105px;
			font-size: 14px;
			line-height: 21px;
			overflow: hidden; }

		.see-more {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 14px;
			line-height: 19px;
			color: $pink;
			font-style: italic; } }


	.venue-section {
		margin-bottom: 60px;

		.inner-wrapper {
			@include gradient-border(4px, top left);
			max-width: 880px;
			padding: 45px 60px;
			margin: 0 auto;
			background-color: white;
			text-align: center; }

		h2.yellow-skew:after {
			left: 0;
			right: 0;
			margin: 0 auto; }

		.address {
			display: block;
			max-width: 600px;
			color: #251c99;
			font-size: 18px;
			line-height: 28px;
			margin: 0 auto 20px;
			font-family: $secondary-style; }

		a {
			color: #e0007a;
			font-style: italic;
			font-family: $secondary-style;

			&:hover {
				font-weight: bold;
				cursor: pointer; } } }


	.venue-img {
		position: absolute;
		bottom: -4px;
		right: -30px;
		max-width: 600px;
		z-index: 10; } }



@media all and (max-width: $tablet) {
	#bootstrapoverride .home-container {
		padding-bottom: 40px;

		.video-container {
			@include image-formating("../../../assets/img/home/img-home-hero-pad.png");
			height: 580px;
			overflow: hidden;
			clip-path: polygon(0 0, 1200px 0, 1200px 75%, 0 100%);

			.video-wrapper {
				display: none; }

			.video-mask {
				height: 580px;
				clip-path: polygon(0 0, 1200px 0, 1200px 75%, 0 100%); } }


		.hero-container {
			margin-bottom: 50px;

			.hero-headings {
				top: 150px; } }


		.introduction-section {
			max-width: 1000px;

			.pictures {
				width: 52%;
				height: 455px;
				margin-right: 25px;

				div {
					&:nth-of-type(1) {
						top: 10px;
						right: 0;
						width: 180px;
						height: 120px;

						img {
							height: 120px; } }

					&:nth-of-type(2) {
						bottom: 0;
						right: 0;
						width: 255px;
						height: 145px;

						img {
							height: 145px; } }

					&:nth-of-type(3) {
						top: 120px;
						left: auto;
						right: 70px;
						width: 310px;
						height: 210px;

						img {
							height: 210px; } } } }


			.introduction {
				width: 44%;

				h3 {
					font-size: 28px;
					line-height: 33px; }

				p {
					font-size: 14px;
					line-height: 24px; }

				.gradient-btn {
					margin: 165px 0 0 0; } } }


		.info-section {
			width: 740px;

			a {
				width: 240px;
				height: 180px;

				&:nth-of-type(2) {
					margin: 0 10px; } }

			.inner-wrapper {
				width: 100%;
				height: 180px;
				padding: 20px 0;

				img {
					width: auto;
					height: 80px;
					margin-bottom: 15px; } }

			a:focus,
			a:active {
				.inner-wrapper {
					@include gradient-border(5px, top right);

					h3 {
						color: #e0007a; } } }



			.border-top,
			.border-right,
			.border-bottom,
			.border-left {
				display: none; } }


		.section-container {
			padding: 0 20px; }



		section.breaking-news-section {
			.inner-wrapper {
				padding: 23px 28px; }

			.content-wrapper {
				width: 100%;
				height: 250px; }

			.img-center {
				height: 120px;
				img {
					height: 120px; } }

			.blue-title {
				max-height: 60px;
				font-size: 14px;
				line-height: 19px; }

			.content {
				display: none; } }


		section.venue-section {
			padding: 0 20px;
			margin-bottom: 0; }

		.venue-img {
			width: 360px; } } }


@media all and (max-width: $mobile) {
	#bootstrapoverride .home-container {
		.video-container {
			height: 390px;
			clip-path: polygon(0 0, 768px 0, 768px 75%, 0 100%);

			.video-mask {
				height: 390px;
				clip-path: polygon(0 0, 768px 0, 768px 75%, 0 100%); } }


		.hero-container {
			.hero-headings {
				top: 140px;
				width: 300px;

				.innovex-logo img {
					width: 200px; }

				p {
					display: none; }

				.gradient-btn {
					display: none; } } }


		.introduction-section {
			margin-bottom: 100px;

			.pictures {
				display: none; }

			.introduction {
				width: 100%;

				h3.yellow-skew {
					font-size: 24px;
					line-height: 27px;
					text-align: center;
					margin-bottom: 50px;

					&:after {
						bottom: -30px;
						right: 0;
						margin: 0 auto; } }


				.gradient-btn {
					display: block;
					width: 240px;
					margin: 165px auto 0;
					padding: 10px 50px; } } }



		.info-section {
			width: 100%;
			padding: 0 15px;

			a {
				width: 100%;
				height: 50px;
				margin: 0 auto 10px;

				&:nth-of-type(2) {
					margin: 0 auto 10px; } }

			.inner-wrapper {
				width: 100%;
				height: 50px;
				padding: 20px 0;
				border-width: 2px;

				img {
					position: absolute;
					top: 5px;
					left: 10px;
					width: 30px;
					height: 30px; }

				h3 {
					position: absolute;
					top: 9px;
					left: 50px;
					font-size: 14px;
					line-height: 27px;
					font-weight: bold;
					font-style: italic; }

				a:focus,
				a:active {
					.inner-wrapper {
						@include gradient-border(2px, top right); } } } }




		.section-container {
			padding: 0 20px; }


		section.breaking-news-section {
			.inner-wrapper {
				padding: 15px 15px 0; }

			.content-wrapper {
				height: 240px;
				margin-bottom: 20px; }

			.see-more {
				font-weight: normal;
				font-style: italic; } }


		section.venue-section {
			margin-bottom: 60px;

			h3 {
				margin-bottom: 80px; }

			.inner-wrapper {
				padding: 30px 25px; }


			.address {
				font-size: 16px;
				line-height: 24px; } }


		.venue-img {
			display: none; } } }








