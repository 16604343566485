.backend-container {
    #ex_info_box {
        #ex_info_title {
            .row {
                padding-bottom: 10px;
                line-height: 38px;
                height: 50px;
                margin-top: 20px;

                h5, .search_box {
                    display: inline-block; }

                .search_box {
                    width: 226px;
                    line-height: 38px;
                    border-radius: 4px;
                    height: 38px;
                    border: 1px solid #cdcdcd;

                    input[name='search'] {
                        width: 180px;
                        padding-left: 10px;
                        height: 28px;
                        border: 0px; }

                    span {
                        display: inline-block;
                        width: 38px;
                        line-height: 38px;
                        height: 38px;
                        text-align: center; } } } }

        .table {
            td:nth-child(5) {
                a {
                    display: inline-block; }

                form {
                    width: auto;
                    display: inline-block; } } } }

    .news-container {
        padding-left: 60px;

        h3, .news_content, .back_btn {
            max-width: 740px; }

        h3 {
            color: #251c99;
            line-height: 24px;
            height: 48px; }

        h4 {
            width: 120px;
            height: 19px;
            color: #e0007a; }

        .news_content {
            color: #666666; }

        .back_btn {
            position: relative;

            a {
                position: absolute;
                right: 0; } } }

    #import_create_box {
        h3 {
            color: #505050; }

        div {
            color: #4a90e2;

            a {
                color: #3f5685; } } }

    #ex_news_box {
        table {
            width: 95%; }

        tbody {
            td:nth-child(6) {
                text-align: right;

                a, form {
                    margin-right: 5px; } } } } }
