.backend-container {
  margin: 0 50px; }

.form-row {
  padding: 5px 0; }

.album-img-box {
  width: 95%; }

.album-img-box img {
  width: 100%;
  height: auto; }

.visitor-info {
  margin-bottom: 20px;

  label {
    width: 200px; } }

.exhibitor-info {
  margin-bottom: 10px;

  div:first-child {
    padding-left: 0;
    padding-right: 15px;

    label {
      width: 130px; } }

  div:nth-child(2n) {
    padding-left: 0;
    padding-right: 0;

    label {
      width: 170px; } } }

.modal-header {
  border: none;
  padding-top: 0; }

.modal-footer {
  border: none; }

.modal-content {
  border-radius: 0;
  button {
    opacity: 1;

    span {
      font-size: 50px;
      color: #232323;
      font-weight: 100;
      opacity: 1; } } }

.modal-dialog {
  @media (min-width: 768px) {
    width: 850px; } }

.modal-body {
  overflow-y: scroll;
  padding: 0 80px;
  height: 650px; }

.product-info {
  margin: 30px -15px 20px -15px; }

.album-img-box {
  padding-left: 20px;

  .head {
    border-radius: 50%;
    width: 140px;
    height: 140px; } }

.btn-edit {
  border: solid 1px #6A91B9;
  background-color: transparent;
  color: #6A91B9;
  border-radius: 20px;

  &:hover, &focus, &:active:focus, &:hover:focus {
    border: solid 1px #6A91B9;
    background-color: transparent;
    color: #6A91B9;
    border-radius: 20px; } }
