//backend nav
#back_nav {
    &>li.active>a {
        background-color: #21b4fe; }

    .expand-menu {
        a[role="button"] {
            span {
                float: right; } }

        ul {
            background-color: rgba(31, 47, 77, 0.8);

            a {
                display: block;
                background-color: rgba(31, 47, 77, 0.8);
                border-radius: 0;

                &:hover {
                    text-decoration: none; } } }

        .nav-dropdown-wrapper {
            padding-left: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            transition: height 3s;
            width: 200px;
            margin-left: -5px;

            .active {
                a {
                    background-color: #21b4fe;
                    color: #ffffff; } }

            li {
                background-color: rgba(31, 47, 77, 0.8);
                display: block;
                height: 30px;
                line-height: 30px;
                width: 180px;
                border-radius: 0;

                &:hover {
                    background-color: rgba(31, 47, 77, 0.8); } } } }

    .menu-collapse {
        height: 0 !important;
        opacity: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important; } }

