#bootstrapoverride nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 1000;
	transition: background-color .3s linear, opacity .3s, left .3s, transform .3s;

	&.mobile-nav {
		display: none; }

	&.nav-small {
		background-color: rgba(11,29,56,.85);
		box-shadow: $box-shadow;
		transform: translate3d(0, -64px, 0);

		.upper-nav {
			border-bottom: none; }

		.computex-logo-container {
			transform: translate3d(0, 60px, 0); }

		.lower-nav .social-links {
			transform: translate3d(0, -40px, 0); }

		.nav-search-btn {
			transform: translate3d(-455px, 60px, 0);

			&.zh-Hant {
				transform: translate3d(-665px, 60px, 0); } } }


	.inner-container {
		position: relative;
		max-width: 1180px;
		margin: 0 auto;
		padding: 0 20px; }


	ul {
		display: flex;
		align-items: center; }

	ul li,
	ul li a,
	ul li span {
		display: inline-block;
		color: white; }


	.computex-logo-container {
		display: inline-block;
		position: absolute;
		top: -5px;
		left: 20px;
		margin: 0 auto;
		max-width: 1180px;
		text-align: left;
		z-index: 1;
		transition: transform .3s; }


	.nav-search-btn {
		position: relative;
		width: 20px;
		height: 24px;
		margin-top: 4px;
		margin-right: 30px;
		transition: width .5s .1s, transform .3s;

		&.expanded {
			width: 155px;
			transition: width .5s; }

		&:hover {
			cursor: pointer; }

		img {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2; } }

	.search-input {
		position: absolute;
		top: 0;
		right: 0;
		transition: opacity .3s .2s;

		&.collapsed {
			opacity: 0;
			pointer-events: none;
			transition: opacity .3s; }

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 1px;
			background-color: white; }

		input {
			display: inline-block;
			width: 150px;
			height: 20px;
			color: white;
			padding: 2px;
			border: none;
			background-color: transparent;
			padding-left: 20px;

			&::-webkit-input-placeholder {
				color: white; }
			&::-moz-placeholder {
				color: white; }
			&:-ms-input-placeholder {
				color: white; }
			&:-moz-placeholder {
				color: white; } } }


	.register-btn {
		border: 2px solid #fecd19;
		padding: 2px 15px;
		font-size: 10px;
		color: #fecd19;
		transition: background-color .3s;

		&:after {
			display: none !important; }

		&:hover {
			background-color: #031d44; } }


	.upper-nav {
		position: relative;
		height: 40px;
		border-bottom: 1px solid white;
		margin-top: 25px;
		margin-bottom: 16px;

		ul {
			position: absolute;
			top: 0;
			right: 0;

			img {
				margin-right: 30px; } }

		.lang-en {
			position: relative;
			margin-right: 20px;

			// &:after
			// 	content: ""
			// 	position: absolute
			// 	top: 3px
			// 	right: -12px
			// 	width: 1px
			// 	height: 17px
 }			// 	background-color: white

		.active {
			background-color: #031d44;
			border-radius: 15px;
			padding: 0 8px; } }


	.lower-nav {
		.social-links {
			float: left;
			padding: 0;
			transition: transform .3s;

			li {
			 	margin-right: 15px; }

			span {
				font-size: 16px; } }

		.nav-links {
			float: right;
			height: 65px;
			margin-top: -15px;
			margin-bottom: 0;

			li {
				margin-left: 30px;

				.register-btn {
					font-size: 10px; } }

			a {
				position: relative;
				cursor: pointer;
				font-size: 18px;
				line-height: 24px;

				&:after {
					content: "";
					position: absolute;
					bottom: -4px;
					left: 0;
					width: 0;
					height: 1px;
					background-color: white;
					opacity: 0;
					transition: opacity .2s, width .2s;
					z-index: 1; }

				&:hover:after {
					opacity: 1;
					width: 100%; }

				&.active-page:before {
					content: "";
					position: absolute;
					bottom: -4px;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: #fecd19;
					z-index: 2; } }

			.big-link {
				position: relative;
				height: 65px;
				padding-top: 20px;

				&>a {
					height: 100%;
					font-size: 0;
					vertical-align: top; }

				a {
					z-index: 10; } }


			.big-link a:after {
				bottom: 15px; }

			.big-link:hover a:after {
				opacity: 1;
				width: 100%; }

			.big-link:hover .sub-menu {
				height: auto;
				opacity: .9;
				pointer-events: auto; }

			.sub-menu {
				display: block;
				position: absolute;
				top: 65px;
				left: -20px;
				width: 130px;
				height: 0;
				background-color: #020d1e;
				padding: 0;
				border-radius: 0 0 3px 3px;
				box-shadow: $box-shadow2;
				opacity: 0;
				pointer-events: none;
				z-index: 9;

				li {
					display: block;
					width: 100%;
					margin: 0;
					padding: 10px 0 10px 20px;
					text-align: left;

					&:hover {
						background-color: rgba(0,0,0,1); }

					a:after {
						display: none; } } } } }




	.opacity-wrapper {
		transition: opacity .3s linear; }


	.opacity-decrease {
		opacity: .95; } }


// animated hamburger to X menu icon
$button-size: 25px;
$transition: .3s;
$color: white;

#bootstrapoverride {
	.lines-button {
		position: fixed;
		top: 15px;
		left: 20px;
		z-index: 9999;
		display: none;
		width: 50px;
		height: 50px;
		padding: 8px 10px 15px;
		background: transparent;
		transition: .3s;
		cursor: pointer;
		user-select: none;
		appearance: none;
		border: none;
		opacity: 1;

		&.bg-color {
			background-color: rgba(199,199,199, .49);
			box-shadow: $box-shadow2;

			.lines {
				background: #444444;

				&:before,
				&:after {
					background: #444444; } } }

		&:focus {
			outline: 0; }

		&.hidden {
			display: none; } }


	@mixin line {
		display: inline-block;
		width: $button-size;
		height: 3px;
		background: $color;
		transition: $transition;
		border-radius: 3px; }


	.lines {
		@include line; //create middle line
		position: relative;

		/*create the upper and lower lines as pseudo-elements of the middle line*/
		&:before,
		&:after {
			@include line;
			position: absolute;
			left: 0;
			content: '';
			transform-origin: $button-size/14 center; }

		&:before {
			top: 8px; }
		&:after {
			top: -8px; } }

	//animating
	.lines-button.x.close .lines {
		/*hide the middle line*/
		background: transparent;

		/*overlay the lines by setting both their top values to 0*/
		&:before,
		&:after {
			transform-origin: 50% 50%;
			top: 0;
			width: $button-size; }

		// rotate the lines to form the x shape
		&:before {
			transform: rotate3d(0,0,1,45deg); }
		&:after {
			transform: rotate3d(0,0,1,-45deg); } }
 }	// end of animated hamburger icon




@media all and (max-width: $tablet) {
	#bootstrapoverride nav.desktop-nav {
		display: none; }

	#bootstrapoverride nav.mobile-nav {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		max-width: 350px;
		height: 100vh;
		padding-bottom: 30px;
		background-color: rgba(16,10,57,.9);
		box-shadow: $box-shadow;
		overflow-y: scroll;

		&.folded {
			left: -355px; }


		.lang-menu {
			justify-content: flex-end;
			align-items: center;
			height: 40px;
			margin-top: 20px;
			padding: 5px;

			li {
				font-size: 18px;
				line-height: 24px;
				margin: 0 10px; }

			.nav-search-btn img {
				position: static; } }


		.nav-search-btn.expanded {
			width: 20px; }

		span.search-input {
			display: block;
			position: static;
			height: 50px;
			opacity: 1;
			border-top: solid 1px rgba(255,255,255,.3);
			transition: height .3s, opacity .3s;

			&:after {
				display: none; }

			&.collapsed {
				height: 0;
				opacity: 0; }

			input {
				height: 50px;

				&::-webkit-input-placeholder {
					color: rgba(255,255,255,.5); }
				&::-moz-placeholder {
					color: rgba(255,255,255,.5); }
				&:-ms-input-placeholder {
					color: rgba(255,255,255,.5); }
				&:-moz-placeholder {
					color: rgba(255,255,255,.5); } } }



		.computex-logo-container {
			position: static;
			width: 100%;
			text-align: center;
			margin-bottom: 20px; }


		.social-links {
			display: flex;
			justify-content: center;
			height: 50px;
			padding: 0;
			margin-bottom: 30px;
			border-top: solid 1px rgba(255,255,255,.3);
			border-bottom: solid 1px rgba(255,255,255,.3);

			li {
			 	margin: 0 20px; }

			span {
				font-size: 20px; } }

		.nav-links {
			display: block;
			height: auto;
			margin: 0 auto;
			padding: 0;
			text-align: center;

			li {
				display: block;


				.register-btn {
					width: 90px;
					font-size: 12px;
					padding: 2px; }

				&:last-of-type {
					margin-top: 20px; } }

			a {
				width: 100%;
				font-size: 18px;
				line-height: 24px;
				padding: 20px;

				&:after {
					display: none; }

				&:focus,
				&.active-page {
					background-color: rgba(2,13,30, .9); } }


			.big-link {
				&>a {
					height: 100%;
					font-size: 0;
					vertical-align: top; }

				a {
					z-index: 10; } }


			.sub-menu {
				display: block;
				width: 100%;
				height: 0;
				background-color: rgba(2,13,30, .9);
				padding: 0;
				opacity: 0;
				pointer-events: none;
				transition: height .3s .1s, opacity .3s;


				&.expanded {
					height: 260px;
					opacity: 1;
					pointer-events: auto;
					transition: height .3s, opacity .3s .1s; }

				li {
					display: block;
					width: 100%;
					margin: 0;
					padding: 0;
					text-align: center; } } } }


	#bootstrapoverride {
		.lines-button {
			display: block; } } }



@media all and (max-width: 500px) {
	#bootstrapoverride nav.mobile-nav {
		max-width: 320px;

		.lang-menu {
			margin-top: 10px;
			margin-bottom: 5px;
			padding: 5px;

			li {
				font-size: 16px;
				line-height: 21px;
				margin: 0 10px; }

			.nav-search-btn img {
				position: static; } }


		span.search-input {
			height: 45px;

			input {
				height: 45px; } }


		.computex-logo-container {
			margin-bottom: 10px; }


		.social-links {
			height: 45px;
			margin-bottom: 20px; }


		.nav-links {
			a {
				padding: 15px; }


			.sub-menu {
				&.expanded {
					height: 220px; } } } }


	#bootstrapoverride {
		.lines-button {
			top: 5px;
			left: 5px; } } }










