.backend-container {
    #subscribe_box {
        #subscribe_title {
            position: relative;

            .notice-box {
                margin-top: 20px;
                width: 200px;

                span {
                    color: #4a90e2; } }

            a {
                position: absolute;
                right: 9%; }

            .notice_email_box {
                display: inline-block;
                line-height: 38px;
                margin-top: 15px;

                input[type="radio"] {
                    margin-top: 0;
                    vertical-align: middle; }

                input[type="text"] {
                    display: inline;
                    height: 38px;
                    line-height: 38px;
                    width: 500px; } } }

        table {
            td:nth-child(3) {
                text-align: right; } } } }
