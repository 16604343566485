#bootstrapoverride .register-container {
	.hero-bg {
		@include image-formating("../../../assets/img/news/img-news-hero.png"); } }












