
// colors
$text-gray: #4a4a4a;
$pink: #e0007a;
$blue: #251c99;
$blue-border: #3721ac;
$yellow: #fdcc1a;



// font stack
@font-face {
	font-family: 'fa';
	src: url('/assets/fonts/Fontawesome.ttf');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'Noto';
	src: url('/assets/fonts/NotoSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Noto';
	src: url('/assets/fonts/NotoSans-Bold.ttf');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Bold.ttf');
	font-weight: bold;
	font-style: normal; }
@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans-BoldItalic.ttf');
	font-weight: bold;
	font-style: italic; }
@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans-ExtraBold.ttf');
	font-weight: 800;
	font-style: normal; }
@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto-Italic.ttf');
	font-weight: normal;
	font-style: italic; }




$primary-style:  "Open Sans", "Lato", "Noto", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Heiti TC", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
$secondary-style: "Roboto", "Lato", "Noto", "Helvetica","AvenirNext-Regular", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;

// image mixin
@mixin image-formating($image) {
	background-image: url($image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%; }


// mixins
@mixin gradient-border($border, $directions...) { // "..." mean variable argument, can have just one or more variables provided
	border-width: $border;
	border-style: solid;
	border-color: $blue-border;
	border-image: linear-gradient(to $directions, #a159c9 0%, #3023ae 25%, #3721ac 70%, #e0007a 90%, #e92f64 100%);
	border-image-slice: 1; }



// x-button
@function sqrt($r) {
	$x0: 1;
	$x1: $x0;

	@for $i from 1 through 2 {
	    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
	    $x0: $x1; }

	@return $x1; }


@mixin x-btn($width, $thickness, $color) {
	$diagonal: $width * sqrt(2);

	position: relative;
	width: $width;
	height: $width;

	&:hover {
		cursor: pointer; }

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: $width/2 - $thickness/2;
		left: -$width*0.2;
		width: $diagonal;
		height: $thickness;
		background-color: $color;
		transform-origin: 50% 50%; }

	&:before {
		transform: rotate(45deg); }
	&:after {
		transform: rotate(-45deg); } }



// shadows
$box-shadow: 2px 1px 6px 2px rgba(0,0,0, .3);
$box-shadow2: 0px 2px 4px 0px rgba(0,0,0, .15);
$inset-shadow: inset 0 0 0 1000px rgba(0,0,0,.3); // darkening of pictures
$inset-border: inset 0 0 0 15px rgba(0,0,0,1);
$text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11);
$text-shadow2: 0 2px 4px rgba(0, 0, 0, 0.5);
$text-shadow3: 0 2px 2px rgba(0, 0, 0, 0.2);



// breakpoints
$tablet: 1200px;
$mobile: 767px;


//bootstrap breakpoints
$xl: 1200px;
$lg: 992px;
$md: 767px;
$sm: 576px;
$xs: 0px;







