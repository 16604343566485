#bootstrapoverride .modal-video-container {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 90vw;
	height: 80vh;
	background-color: black;
	margin: 10vh auto;
	box-shadow: $box-shadow;
	text-align: center;
	z-index: 9999;

	&.playing {
		display: flex;
		align-items: center;
		justify-content: center; }

	.x-btn {
		@include x-btn(25px, 1px, white);
		position: absolute;
		top: 25px;
		right: 25px; }

	iframe {
		width: 80%;
		height: 90%;
		max-width: 1280px;
		max-height: 720px; } }

#bootstrapoverride .media-container {
	.hero-media {
		@include image-formating("../../../assets/img/media/img-media-hero.png"); }


	section.carousel-section {
		max-width: 1100px;

		.slick-slide {
			&:nth-of-type(odd) {
				padding-right: 10px; }

			&:nth-of-type(even) {
				padding-left: 10px; } } }



	section.online-registration {
		max-width: 780px;
		text-align: center;

		p {
			margin-bottom: 40px; } }

	.btn-arrow-left {
		left: -50px; }
	.btn-arrow-right {
		right: -50px; }


	.gradient-btn {
		display: block;
		width: 210px;
		margin: 0 auto;
		padding: 10px; }

	.more-btn {
		margin-top: 50px; }



	section.press-releases {
		.carousel .img-center img {
			height: 125px; }

		.carousel .row {
			display: flex;
			align-items: center;
			height: 125px;
			margin-bottom: 18px; } }



	section.logo-download {
		max-width: 980px;

		.row>div {
			margin-bottom: 40px; }

		.img-center {
			height: 165px;
			padding: 25px 0;
			border: solid 2px #d3d3dc;
			margin-bottom: 10px;

			img {
				height: calc(165px - 50px);
				object-fit: contain; } }


		p {
			font-size: 14px;
			line-height: 19px;
			color: $blue;
			margin-bottom: 5px; }


		.img-download {
			font-size: 18px;
			line-height: 22px;
			font-weight: bold;
			font-style: italic;
			transition: color .2s;
			cursor: pointer;

			&:hover {
				color: $pink; } } } }



@media all and (max-width: $tablet) {
	#bootstrapoverride .media-container {
		section {
			margin-bottom: 100px; }

		section.carousel-section {
			max-width: 100%; }


		section.press-releases {
			.carousel .row {
				padding: 0 20px; } }


		section.news-section {
			.content-wrapper {
				width: 225px;
				height: 280px; }


			.inner-wrapper {
				@include gradient-border(4px, top left);
				padding: 25px 10px; }


			h4 {
				font-size: 14px;
				line-height: 19px; }

			.content {
				display: none; }

			.see-more {
				position: absolute;
				bottom: 0;
				left: 0;
				color: $pink;
				font-weight: 700; }


			.btn-arrow-left-mobile {
				top: 110px;
				left: -70px; }
			.btn-arrow-right-mobile {
				top: 110px;
				right: -70px; } } } }





@media all and (max-width: $mobile) {
	#bootstrapoverride .modal-video-container {
		width: 100vw;

		iframe {
			width: 95%;
			height: 45%; } }


	#bootstrapoverride .media-container {
		p {
			font-size: 14px;
			line-height: 24px; }

		section {
			padding: 0 15px;
			margin-bottom: 100px; }


		.more-btn {
			display: block;
			margin-top: 30px; }


		section.pre-registration {
			p {
				text-align: left; } }


		section.press-releases {
			.carousel .row {
				height: auto;
				padding: 0;

				div[class^="col-"] {
					height: auto; }

				.date {
					font-size: 14px;
					line-height: 19px; }

				.more {
					display: none; }

				h4 {
					font-size: 14px;
					line-height: 19px; } } } } }


















