#bootstrapoverride .event-container {
	.hero-bg {
		@include image-formating("../../../assets/img/events/img-event-hero.png"); }


	section.forum-section {
		padding: 0 15px;

		h2 {
			margin-bottom: 120px; } } }








