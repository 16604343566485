#bootstrapoverride {
	section.exhibitor-list-section {
		max-width: 1020px;
		padding: 0 20px;

		.dropdown-wrapper-filter {
			position: relative;
			width: 100%;
			height: 40px;
			border-bottom: solid 2px #d9017c;
			margin: 0 auto 45px;

			//arrow
			&:after {
				content: url("../../../assets/img/icons/btn-dropdown.svg");
				position: absolute;
				right: 0;
				top: 10px;
				pointer-events: none; }

			label {
				font-size: 24px;
				line-height: 33px;
				color: $blue;
				font-weight: bold;
				font-style: italic; }

			select {
				position: absolute;
				right: 0;
				width: 75%;
				height: 35px;
				padding: 5px 9px;
				border: none;
				appearance: none;
				background-color: transparent;
				cursor: pointer;
				outline: none;
				font-size: 14px;
				color: #777777;
				font-family: $secondary-style;
				font-size: 16px;
				line-height: 21px; } }


		.img-center {
			width: 180px;
			margin-bottom: 20px;

			img {
				min-height: 170px;
				height: 170px;
				object-fit: contain; } }


		.row>div {
			text-align: center;
			margin-bottom: 90px;
			max-height: 223px;

			h5 {
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;
				font-size: 14px;
				line-height: 19px;
				font-weight: bold;
				text-align: -webkit-center;
				width: 180px;
				max-height: 76px; }

			p {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				font-size: 12px;
				line-height: 14px;
				font-style: italic;
				text-align: -webkit-center;
				width: 180px;
				max-height: 28px; }

			span {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				text-align: -webkit-center;
				overflow: hidden;
				max-height: 22px; } } }


	// section.floor-plan
	// 	max-width: 800px

	// 	.img-border
	// 		width: 280px
	// 		border: solid 4px #4a4a4a
	// 		text-align: center
	// 		padding: 10px 0 15px
	// 		margin: 0 auto
	// 		cursor: pointer

	// 		img
	// 			width: 130px
	// 			height: 130px

	// 		p
	// 			font-size: 22px
	// 			line-height: 24px
	// 			font-weight: bold
	// 			font-style: italic

	section.floor-plan {
		text-align: center;

		img {
			margin-bottom: 20px; } }




	section.exhibitor-news {
		.gradient-border {
			@include gradient-border(1px, top left);
			width: 250px;
			padding-bottom: 5px;
			margin: 0 auto;
			text-align: center;
			overflow: hidden;

			&>a {
				display: block; } }

		.img-center {
			margin-bottom: 14px;

			img {
				height: 190px;
				object-fit: contain; } }

		.pink-date {
			margin-bottom: 10px;
			padding: 0 10px; }

		.blue-title {
			display: flex;
			//align-items: center
			justify-content: center;
			height: 60px;
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 30px;
			padding: 0 10px; }


		.presenter {
			height: 24px;
			margin: 0;
			padding: 0 10px;
			font-size: 14px;
			line-height: 19px; }


		.yellow-skew-before:before {
			left: 0;
			right: 0;
			margin: 0 auto; }

		.btn-arrow-left {
			top: 120px;
			left: -50px; }
		.btn-arrow-right {
			top: 120px;
			right: -50px; } }



	section.video {
		.carousel .inner-container {
			width: 345px;
			height: auto;
			margin: 0 auto;

			p {
				max-height: 70px;
				font-size: 18px;
				line-height: 23px;
				font-weight: bold;
				color: $blue;
				overflow: hidden; }

			.img-center {
				position: relative;
				margin-bottom: 12px;
				cursor: pointer;

				&:after {
					content: "";
					position: absolute;
					top: calc(230px/2 - 35px/2);
					left: calc(50% - 25px);
					width: 50px;
					height: 35px;
					background-image: url(../../../assets/img/icons/yt-icon.png);
					background-size: contain;
					background-repeat: no-repeat; }


				img {
					width: 100%;
					height: 230px; } } }


		.btn-arrow-left {
			left: -50px; }
		.btn-arrow-right {
			right: -50px; }


		.video-container {
			display: none; } }



	section.photos {
		.carousel .inner-container {
			width: 345px;
			height: 480px;
			margin: 0 auto;

			.img-center {
				margin-bottom: 20px;

				img {
					height: 230px; } }

			.inset-shadow {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 230px;
				box-shadow: $inset-border;
				opacity: 0;
				transition: opacity .3s; }

			.album-wrapper {
				position: relative;

				&:hover .inset-shadow {
					opacity: 1; }
				&:hover .album-info {
					opacity: 1; } }

			.album-info {
				position: absolute;
				top: 30px;
				left: 0;
				right: 0;
				width: 280px;
				height: 175px;
				margin: 0 auto;
				opacity: 0;
				background-color: rgba(0,0,0,.7);
				transition: opacity .3s;

				.album-name {
					position: absolute;
					top: 10px;
					left: 10px;
					right: 10px;
					max-height: 100px;
					font-size: 18px;
					font-weight: bold;
					font-style: italic;
					color: white;
					overflow: hidden; }

				.pic-count {
					position: absolute;
					bottom: 10px;
					left: 10px;
					font-size: 14px;
					font-style: italic;
					color: $pink; } } }


		.btn-arrow-left {
			top: 200px;
			left: -50px; }
		.btn-arrow-right {
			top: 200px;
			right: -50px; } }

	section.speakers-section {
		max-width: 1000px;
		padding: 0 20px;
		margin-bottom: 60px;

		.speaker:hover {
			cursor: pointer; }

		.speaker .speaker-detail {
			position: fixed;
			top: 10vh;
			left: 0;
			right: 0;
			width: 500px;
			height: 80vh;
			margin: 0 auto;
			padding: 60px 65px 30px;
			background-color: white;
			text-align: center;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
			opacity: 0;
			pointer-events: none;
			z-index: 9999;
			transition: opacity .3s;
			cursor: auto;
			overflow: scroll;

			.x-btn {
				@include x-btn(25px, 1px, #2a2a2a);
				position: absolute;
				top: 25px;
				right: 25px; }

			.img-center {
				width: 180px;
				margin: 0 auto;

				img {
					height: 200px; } }

			h5 {
				color: #031d44;
				font-size: 18px;
				font-weight: bold; }

			.yellow-skew {
				font-size: 0;
				line-height: 0;
				margin-bottom: 20px; }

			.yellow-skew:after {
				left: 0;
				right: 0;
				bottom: 10px;
				width: 25px;
				height: 8px;
				margin: 0 auto; }

			.title {
				font-weight: bold;
				font-size: 14px;
				line-height: 20px; }

			.fa {
				display: inline-block;
				font-size: 20px;
				margin: 10px 5px 40px;
				color: #9b9b9b; }

			.fa-facebook {
				transition: color .2s;
				&:hover {
					color: #3b5998; } }

			.fa-twitter {
				transition: color .2s;
				&:hover {
					color: #55acee; } }

			.bio {
				text-align: left;
				font-size: 14px;
				line-height: 22px;
				font-style: normal;
				color: #6a6b6d;

				&.en {
					margin-bottom: 20px; } } }


		h2.yellow-skew:after {
			left: 0;
			right: 0;
			margin: 0 auto; }

		.row>div {
			height: 320px;
			margin-bottom: 40px;
			text-align: center;

			.inner-wrapper {
				width: 100%;
				max-width: 180px;
				height: 320px;
				padding: 15px;
				background-color: white;
				overflow: hidden;
				margin: 0 auto; } }


		.inner-wrapper .hover-wrapper {
			&:hover img {
				transform: scale3d(1.1, 1.1, 1); }

			&:hover h5,
			&:hover p {
				color: $blue; } }



		.img-center {
			width: 100%;
			max-width: 200px;
			margin: 0 auto;
			overflow: hidden;

			img {
				width: 100%;
				height: 180px;
				object-fit: cover;
				transition: transform .5s; } }

		.speaker-info {
			text-align: center;
			padding: 10px 0;

			h5 {
				font-weight: bold;
				margin-bottom: 8px;
				transition: color .5s; }

			p {
				font-family: $secondary-style;
				font-style: italic;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				transition: color .5s; } } } }



@media all and (max-width: $tablet) {
	#bootstrapoverride {
		// section.floor-plan
		// 	.img-border
		// 		width: 200px

		// 		img
		// 			width: 100px
		// 			height: 100px

		// 		p
		// 			font-size: 18px
		// 			line-height: 22px


		section.exhibitor-news {
			.gradient-border {
				width: 220px; } }


		section.video {
			.carousel .inner-container {
				width: 285px;
				height: 240px;
				margin: 0 auto;

				.img-center {
					&:after {
						top: calc(160px/2 - 35px/2); }

					img {
						height: 160px; } } } }



		section.photos {
			.carousel .inner-container {
				width: 280px;
				height: 500px;

				.img-center {
					margin-bottom: 80px;
					img {
						height: 185px; } }

				.inset-shadow {
					display: none; }

				.album-info {
					position: absolute;
					top: 180px;
					left: 0;
					right: 0;
					width: 280px;
					height: 80px;
					opacity: 1;
					background-color: transparent;

					.album-name {
						top: 10px;
						left: 0;
						font-size: 14px;
						color: #4a4a4a; }

					.pic-count {
						bottom: 10px;
						left: 0; } } } } } }



@media all and (max-width: $mobile) {
	#bootstrapoverride {
		section.exhibitor-list-section {
			h2 {
				margin-bottom: 100px; }

			.dropdown-wrapper-filter {
				label {
					position: absolute;
					top: -25px;
					font-size: 16px;
					line-height: 21px; }

				select {
					width: 100%;
					padding: 5px 25px 0 0;
					font-size: 12px;
					line-height: 19px; } }

			.img-center {
				margin-bottom: 10px;
				width: 100%; }

			.row>div {
				margin-bottom: 40px;

				p {
					display: none; }

				h5 {
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap; } } }


		.login-modal-container {
			.inner-wrapper {
				padding: 60px 20px; } }



		// section.floor-plan
		// 	.img-border
		// 		width: 100%
		// 		border: solid 2px #4a4a4a
		// 		padding: 10px 15px
		// 		margin: 0 auto
		// 		cursor: pointer

		// 		img
		// 			float: left
		// 			width: 30px
		// 			height: 30px

		// 		p
		// 			float: left
		// 			font-size: 14px
		// 			line-height: 30px
		// 			margin-left: 10px



		section.exhibitor-news {
			.gradient-border {
				width: 100%;
				max-width: 400px;
				padding: 15px;
				margin: 0 auto 15px;
				text-align: left; }

			.img-center {
				display: none; }

			.pink-date {
				margin-bottom: 0;
				padding: 0; }

			.blue-title {
				height: auto;
				margin: 0 0 30px 0;
				padding: 0; }

			.presenter {
				padding: 0; }


			.yellow-skew-before:before {
				margin: 0; } }




		section.video {
			.carousel .inner-container {
				width: 100%;
				max-width: 400px;
				height: 270px;
				margin-bottom: 25px;

				.img-center {
					&:after {
						top: calc(180px/2 - 35px/2); }

					img {
						height: 180px; } } }

			.more-btn {
				margin-top: 20px; } }



		section.photos {
			display: none; }

		section.speakers-section {
			.row>div {
				height: 300px;
				margin-bottom: 40px;
				text-align: center;

				.inner-wrapper {
					max-width: 200px;
					padding: 0;
					margin: 0 auto;
					background-color: transparent; } }

			.speaker .speaker-detail {
				top: 7vh;
				width: 100vw;
				height: 85vh;
				margin: 0 auto;
				padding: 50px 20px 30px;

				.img-center {
					max-width: 135px;

					img {
						height: 150px; } }

				h5 {
					font-size: 14px; }

				p.bio {
					text-align: left; } } } } }









