#bootstrapoverride .forum-section {
	max-width: 920px;
	margin: 0 auto 100px;


	.tab-box-container {
		position: relative;

		.tab {
			position: absolute;
			top: -52px;
			height: 60px;
			border: 2px solid #9b9b9b;
			padding: 15px 0;
			text-align: center;
			font-size: 18px;
			font-weight: 600;
			color: #9b9b9b;

			&:hover {
				cursor: pointer; } }

		.tab-1 {
			left: 0;
			width: calc(100% / 3); }
		.tab-2 {
			left: calc(100%/3 - 2px);
			width: calc(100% / 3 + 2px); }
		.tab-3 {
			left: calc(100%/3 * 2 - 2px);
			width: calc(100% / 3 + 2px); }

		.tab.active {
			top: -58px;
			height: 66px;
			z-index: 2;
			color: #313131; }

		.tab-box-main {
			position: relative;
			width: 100%;
			z-index: 3;
			padding-bottom: 45px; } }


	h2 {
		margin-bottom: 160px;
		text-align: center;
		font-size: 30px;
		line-height: 41px; }


	.tab.active,
	.tab-box-main {
		@include gradient-border(6px, right); }

	.tab-box-main {
		display: none;
		background-color: white;
		overflow-x: hidden;
		overflow-y: scroll;

		&.active {
			display: block; } }


	table {
		width: 100%;
		border-collapse: separate;
		border-bottom: solid 2px #eeeeee;

		thead {
			background-color: #251c99; }

		th {
			font-size: 24px;
			line-height: 33px;
			font-weight: bold;
			font-style: italic;
			color: white;
			padding: 15px 0 20px;
			vertical-align: top;
			overflow: hidden;

			span {
				display: none; } }

		tr:first-of-type .time-start {
			top: 5px; }

		td {
			position: relative;
			width: 45%;
			padding: 22px;
			vertical-align: top;
			overflow: hidden;
			font-size: 18px;
			color: #251c99;
			font-weight: bold;
			overflow: visible;

			&.filled {
				border-top: 2px solid #eeeeee;

				&.expandable {
					cursor: pointer; }

				&.append {
					border: solid 3px black;
					border-bottom: none; }

				.append-panel {
					visibility: hidden;
					position: absolute;
					background-color: white;
					width: calc(100% + 6px);
					z-index: 1;
					margin-left: -25px;
					margin-top: 2px;
					padding: 10px 22px 10px 0;
					border: solid 3px black;
					border-top: none;

					label {
						padding-left: 22px; }

					li {
						&:hover {
							color: #e92f64; } }



					&.active {
						visibility: visible; } } }

			&:first-of-type {
				width: 10%;
				padding: 35px 55px; }

			&:nth-of-type(2) {
				border-right: 2px solid #eeeeee;
				border-left: 2px solid #eeeeee; } }

		tr:first-of-type td {
			height: 90px;
			padding: 22px;
			border-top: none;

			&.filled .append-panel {
				margin-top: 22px; }

			.active {
				margin-top: 43px; } }

		tbody tr:last-of-type {
			border-bottom: 2px solid #eeeeee; } }


	.time-start {
		display: inline-block;
		color: #171717;
		font-size: 24px;
		font-weight: bold;
		line-height: 33px; }


	.time-start {
		position: absolute;
		top: -15px;
		left: 25px;
		margin-bottom: 10px;

		&:after {
			content: "";
			position: absolute;
			left: 30px;
			bottom: -27px;
			width: 3px;
			height: 20px;
			background-color: #2c11a9; } }


	.remark {
		display: inline-block;
		color: #e0007a;
		margin: 30px 30px 0;
		line-height: 18px;
		font-style: italic; } }

#bootstrapoverride {
	.speaker:hover {
		cursor: pointer; }

	.speaker .speaker-detail {
		position: fixed;
		top: 10vh;
		left: 0;
		right: 0;
		width: 500px;
		height: 80vh;
		margin: 0 auto;
		padding: 60px 65px 30px;
		background-color: white;
		text-align: center;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
		opacity: 0;
		pointer-events: none;
		z-index: 9999;
		transition: opacity .3s;
		cursor: auto;
		overflow: scroll;

		.x-btn {
			@include x-btn(25px, 1px, #2a2a2a);
			position: absolute;
			top: 25px;
			right: 25px; }

		.img-center {
			width: 180px;
			margin: 0 auto;

			img {
				height: 200px; } }

		h5 {
			color: #031d44;
			font-size: 18px;
			font-weight: bold; }

		.yellow-skew {
			font-size: 0;
			line-height: 0;
			margin-bottom: 20px; }

		.yellow-skew:after {
			left: 0;
			right: 0;
			bottom: 10px;
			width: 25px;
			height: 8px;
			margin: 0 auto; }

		.title {
			font-weight: bold;
			font-size: 14px;
			line-height: 20px; }

		.fa {
			display: inline-block;
			font-size: 20px;
			margin: 10px 5px 40px;
			color: #9b9b9b; }

		.fa-facebook {
			transition: color .2s;
			&:hover {
				color: #3b5998; } }

		.fa-twitter {
			transition: color .2s;
			&:hover {
				color: #55acee; } }

		.bio {
			text-align: left;
			font-size: 14px;
			line-height: 22px;
			font-style: normal;
			color: #6a6b6d;

			&.en {
				margin-bottom: 20px; } } } }



@media all and (max-width: $tablet) {
	#bootstrapoverride .forum-section {
		padding: 0 15px;

		.tab-box-container {
			.tab-3 {
				width: calc(100% / 3 + 2px); } }

		.remark {
			font-size: 14px;
			line-height: 19px; } } }




@media all and (max-width: $mobile) {
	#bootstrapoverride .forum-section {
		padding: 0;

		.tab.active,
		.tab-box-main {
			@include gradient-border(3px, right); }

		.tab-box-main {
			height: 540px; }


		.tab-box-container {
			.tab {
				padding: 5px;
				font-size: 14px; }

			.tab.active {
				top: -54px;
				height: 62px; }

			.tab-box-main {
				padding-bottom: 15px; } }


		table {
			th {
				position: relative;
				font-size: 14px;
				line-height: 21px;
				padding: 15px 20px 20px; }

			td {
				height: 100px;
				//padding: 5px 10px
				font-size: 14px;
				font-weight: normal;

				&.filled {
					border-top: 2px solid #eeeeee;

					.append-panel {
						padding: 10px 25px 10px 0;
						margin-top: 32px; }

					label {
						padding-left: 25px; } }

				&:first-of-type {
					position: relative;
					width: 10%;
					padding: 5px;
					border-right: 2px solid #eeeeee; }

				&:nth-of-type(2) {
					border-right: none;
					border-left: none; } }

			tr:first-of-type td {
				padding: 25px;

				&.filled .append-panel {
					margin-left: -28px;
					margin-top: 48px; } }

			tr:first-of-type td:first-of-type {
				height: 120px;
				padding: 25px; } }


		.time-start {
			left: 9px;
			top: -9px;
			font-size: 12px;
			font-weight: bold;
			line-height: 19px;

			&:after {
				left: 0;
				right: 0;
				bottom: -45px;
				width: 1px;
				margin: 0 auto;
				opacity: .2; } }


		.remark {
			margin: 10px 10px 0;
			font-size: 12px;
			line-height: 18px; }


		table th span {
			display: block;
			position: absolute;
			top: 5px;
			height: 40px;
			padding: 10px 0;
			font-size: 14px;
			cursor: pointer;
			color: white;
			opacity: .4; }


		th.col-1 {
			padding-left: 10%;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: calc(10% + 50px);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 7px;
				border-color: transparent transparent white transparent; } }

		th.col-2 {
			padding-right: 10%;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: calc(10% + 25px);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 7px;
				border-color: transparent transparent white transparent; } }


		th.col-1.active .mobile-th-1 {
			right: 20px;
			width: 80px;
			text-align: right;

			&:after {
				content: "";
				position: absolute;
				top: 10px;
				left: 10px;
				width: 2px;
				height: 23px;
				background-color: #ffffff;
				opacity: .25; } }

		th.col-2.active .mobile-th-2 {
			left: 20px;
			width: 120px;
			text-align: left;

			&:after {
				content: "";
				position: absolute;
				top: 10px;
				right: 10px;
				width: 2px;
				height: 23px;
				background-color: #ffffff;
				opacity: .25; } }


		th.col-2 {
			text-align: right; }


		.col-1,
		.col-2 {
			display: none;
			width: initial;

			&.active {
				display: table-cell; } } } }















