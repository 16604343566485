@import "general_information";
@import "pages";
@import "news";
@import "agenda";
@import "speaker";
@import "sponsor";
@import "exhibitor";
@import "subscribe";

.logo {
    text-align: center;
    img {
        width: 170px;
        height: 36px; } }

.sub_logo {
    color: #3f5685;
    background-color: #e0e4e8;
    width: 170px;
    height: 22px;
    text-align: center;
    margin: 5px auto 10px; }

.mouse {
    &:hover {
        cursor: pointer; } }

.img_box {
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        max-height: 100px;
        top: -9999px;
        bottom: -9999px;
        right: -9999px;
        left: -9999px;
        margin: auto; } }

.img_holder {
    width: 180px;
    height: 200px;
    display: inline-block;
    // background-color: #666666
    border: 1px solid #cdcdcd;
    padding: 0; }

.select_file_btn {
    vertical-align: top;
    padding-left: 15px;
    display: inline-block;
    width: 240px;

    label {
        border: 1px solid #cdcdcd;
        width: 102px;
        line-height: 38px;
        padding-left: 12px;
        color: #919191; }

    p {
        color: #4a90e2; } }


#back_nav {
    li {
        background-color: rgba(31, 47, 77, 0.5);
        border-color: rgba(31, 47, 77, 0.5);
        margin-top: 0;
        padding: 0 5px;

        a {
            color: #ffffff;
            border-radius: 0;

            &:hover {
                background-color: #21b4fe; } } }

    #footer_nav {
        position: fixed;
        bottom: 0;
        width: 200px;
        height: 50px;
        line-height: 33px;
        background-color: #3f5685;

        a {
            padding-left: 20px; }

        span {
            margin-right: 5px; } } }
