#bootstrapoverride .news-list-container {
	.hero-news-list {
		@include image-formating("../../../assets/img/news/img-news-hero.png"); }


	section {
		max-width: 1000px;
		padding: 0 20px;

		a {
			display: block; }

		.img-center {
			margin-bottom: 8px;

			img {
				height: 155px; } } }

	.row>div {
		margin-bottom: 80px; } }



#bootstrapoverride {
	.news-container {
		.hero-news {
			@include image-formating("../../../assets/img/news/img-news-hero.png"); } }


	section.carousel-section {
		max-width: 1100px; }


	.gradient-btn {
		display: block;
		width: 210px;
		margin: 0 auto;
		padding: 10px; }

	.more-btn {
		margin-top: 50px; }


	section.news-section {
		max-width: 1280px;

		.content-wrapper {
			position: relative;
			width: 240px;
			height: 410px;
			margin: 0 auto; }

		.content-wrapper>a {
			display: block;

			&:hover .see-more {
				font-weight: bold;
				text-decoration: underline; } }


		.inner-wrapper {
			@include gradient-border(4px, top left);
			padding: 45px 100px 35px;
			background-color: white; }


		.img-center {
			height: 155px;
			margin-bottom: 10px;

			img {
				height: 155px; } }


		.content {
			max-height: 105px;
			font-size: 14px;
			line-height: 21px;
			overflow: hidden; }

		.see-more {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 14px;
			line-height: 19px;
			color: $pink;
			font-style: italic; }


		.btn-arrow-left {
			left: -70px; }
		.btn-arrow-right {
			right: -70px; } } }



@media all and (max-width: $tablet) {
	#bootstrapoverride {
		section.news-section {
			.content-wrapper {
				width: 225px;
				height: 280px; }


			.inner-wrapper {
				padding: 25px 10px; }


			h4 {
				font-size: 14px;
				line-height: 19px; }

			.content {
				display: none; }

			.see-more {
				position: absolute;
				bottom: 0;
				left: 0;
				color: $pink;
				font-weight: 700; }


			.btn-arrow-left-mobile {
				top: 110px;
				left: -70px; }
			.btn-arrow-right-mobile {
				top: 110px;
				right: -70px; } } } }



@media all and (max-width: $mobile) {
	#bootstrapoverride {
		section.news-section {
			.inner-wrapper {
				max-width: 400px;
				margin: 0 auto;
				padding: 20px; }

			.content-wrapper {
				width: 100%;
				height: auto;
				margin-bottom: 20px; }

			.img-center {
				display: none; }


			h4 {
				font-size: 14px;
				line-height: 19px; }

			.see-more {
				display: none; } } } }





