#bootstrapoverride .news-container.news-detail {
	padding-bottom: 20px;

	.hero-news {
		@include image-formating("../../../assets/img/news/img-news-hero.png"); }

	.news-detail-section {
		text-align: left;
		max-width: 780px;
		margin: 50px auto 60px;
		padding: 0 20px;

		a {
			font-size: 18px;
			color: blue; }

		.news-title {
			color: $blue;
			font-weight: 700;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 10px;
			text-align: left; }


		img {
			display: block;
			width: 100%;
			height: auto;
			margin: 0 auto 30px;
			object-fit: cover; }


		h5 {
			font-weight: bold; } }



	.news-quick-nav {
		max-width: 1280px;
		margin: 0 auto 40px;
		padding: 0 50px;

		.inner-container {
			position: relative; }

		.content-wrapper {
			width: 255px;
			height: 260px;
			margin: 0 auto; }

		.img-center {
			margin-bottom: 10px;

			img {
				height: 155px;
				object-fit: contain; } }


		.btn-arrow-left {
			left: -30px; }
		.btn-arrow-right {
			right: -30px; }

		.btn-arrow-left-mobile {
			left: -55px; }
		.btn-arrow-right-mobile {
			right: -55px; } } }




@media all and (max-width: $tablet) {
	#bootstrapoverride .news-container.news-detail {
		.news-quick-nav {
			width: 100%;
			padding: 0 15px;

			.content-wrapper {
				width: 225px;
				height: 280px;
				margin: 0 auto; }

			.img-center {
				margin-bottom: 10px;

				img {
					height: 135px; } }

			h4 {
				font-size: 14px;
				line-height: 19px; } } } }



@media all and (max-width: $mobile) {
	#bootstrapoverride .news-container.news-detail {
		.news-detail-section {
			img {
				height: 200px; } }


		.news-quick-nav {
			display: none; } } }




