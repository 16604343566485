.backend-container {
    .album-box {
        img {
            background-color: #cdcdcd; } }

    #sponsor_box {
        .content_box {
            label {
                width: 98.2%;
                height: 40px;
                border-radius: 4px;
                background-color: #efefef;
                display: block;
                padding-left: 20px;
                line-height: 40px; }

            .row {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 60px;

                img {
                    background-color: #666666; } } } }

    #category_box {
        .create_box {
            margin-top: 20px;
            margin-right: 10px;
            text-align: right;
            width: 950px;
            padding-right: 15px;

            input {
                display: inline-block;
                width: 230px;
                height: 38px;
                margin-right: 10px;
                padding-left: 12px; } } } }
