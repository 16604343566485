#bootstrapoverride {
	footer {
		position: relative;
		background-color: #2a3648;
		text-align: center;
		z-index: 10;

		.inner-wrapper {
			position: relative;
			max-width: 1180px;
			height: 200px;
			margin: 0 auto;
			padding-top: 90px; }

		.computex-logo {
			position: absolute;
			top: 30px;
			left: 0; }

		.row {
			display: flex;
			margin-bottom: 20px; }

		.row div {
			display: flex;
			align-items: center;
			text-align: left;
			margin-bottom: 15px;

			img {
				margin-left: 30px;
				margin-right: 10px; } }

		.row .align-right {
			display: block;
			text-align: right;
			bottom: 20px;
			right: 30px;

			a {
				color: white; } }

		.row span {
			color: white;
			font-size: 12px; }

		.separator {
			position: relative;
			font-weight: bold;

			&:after {
				content: "";
				position: absolute;
				top: 1px;
				right: -12px;
				width: 1px;
				height: 17px;
				background-color: white; } }

		.copy {
			display: inline-block;
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: white;
			font-size: 13px;
			font-family: $secondary-style; } } }


@media all and (max-width: $tablet) {
	#bootstrapoverride footer {
		.inner-wrapper {
			height: 280px;
			padding: 90px 20px 0; }

		.computex-logo {
			left: 20px; } } }



@media all and (max-width: $mobile) {
	#bootstrapoverride footer {
		.inner-wrapper {
			height: 340px; }

		.row {
			margin-bottom: 20px; }


		.row div {
			display: block;

			img {
				margin-left: 0; }

			a {
				display: inline-block;
				width: calc(100% - 50px);
				vertical-align: middle; } }


		.separator {
			display: block;
			margin-bottom: 15px;

			&:after {
				display: none; } }


		.copy {
			bottom: 10px;
			left: 10px;
			right: 10px;
			font-size: 12px;
			line-height: 18px; } } }


@media all and (max-width: $lg) {
	#bootstrapoverride footer {
		.row {
			display: block; } } }



