#bootstrapoverride form {
	max-width: 780px;
	margin: 100px auto;

	.row {
		margin-bottom: 50px;

		&.margin-small {
			margin-bottom: 20px; } }

	.input-wrapper {
		position: relative;

		label {
			position: absolute;
			top: -34px;
			left: 0; } }


	.dropdown-wrapper {
		&:before {
			content: "";
			position: absolute;
			right: 55px;
			top: 5px;
			width: 1px;
			height: 40px;
			background-color: #cccccc; }

		&:after {
			content: url("../../../assets/img/icons/btn-dropdown.svg");
			position: absolute;
			right: 21px;
			top: 17px;
			pointer-events: none; } }


	label,
	p {
		font-size: 18px;
		line-height: 30px;
		font-weight: bold;
		font-style: italic; }


	input:not([type="submit"]),
	select,
	textarea {
		width: 100%;
		height: 50px;
		border: solid 1px #cccccc;
		border-radius: 4px;
		padding: 10px 20px;
		font-family: $secondary-style;
		font-size: 18px;
		line-height: 24px;
		appearance: none;
		background-color: white;
		cursor: pointer;
		outline: none;

		&::-webkit-input-placeholder {
		   color: #cccccc; }
		&:-moz-placeholder {
		   color: #cccccc; }
		&::-moz-placeholder {
		   color: #cccccc; }
		&:-ms-input-placeholder {
		   color: #cccccc; }


		&:focus {
			border: solid 2px #fecd19; } }

	textarea {
		height: 250px; }


	.radio-wrapper {
		position: relative;
		width: 100%;
		height: 50px;

		input {
			display: none; }

		label {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 50px;
			border: solid 1px #cccccc;
			border-radius: 4px;
			padding: 15px 20px;
			font-family: $secondary-style;
			font-size: 16px;
			line-height: 19px;
			font-style: normal;
			text-align: center;
			cursor: pointer; }

		input:checked + label {
			background-color: #251c99;
			color: white; } }


	.btn {
		display: block;
		width: 210px;
		padding: 10px 0;
		margin: 50px auto 0; } }
