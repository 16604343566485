.backend-container {
    .word-break {
        word-break: break-all; }

    .no_table {
        background-color: #ffffff;
        border-bottom-color: #ffffff;
        border-top-color: #ffffff; }

    .no_table_botton {
        border-collapse: inherit; }

    .sub_box_title {
        width: 100%;
        height: 80px;

        a, input[value="+新增"] {
            float: right;
            margin: 20px 30px 20px auto; } }

    table {
        margin-top: 20px;

        thead {
            tr th {
                background-color: #efefef; } } }

    .create_form {
        margin-top: 20px;
        width: 70%;

        .form-group {
            margin-top: 30px;

            button[type="submit"] {
                margin-right: 10px; } } }

    .general_box {
        // padding-left: 30px

        table {
            tr {
                border-bottom: 1px solid #cdcdcd;

                td, th {
                    padding-left: 10px; }

                // th:nth-child(1), td:nth-child(1)
                //     width: 230px

                // th:nth-child(2), td:nth-child(2)
                //     width: 250px

                // th:nth-child(3), td:nth-child(3)
                //     width: 240px

                // th:nth-child(4), td:nth-child(4)
                //     width: 100px

                // th:nth-child(5), td:nth-child(5),
                // th:nth-child(6), td:nth-child(6)
                //     width: 70px

                td:nth-child(1) {
                    iframe, img {
                        background-color: #666666; } }

                td:nth-child(2), td:nth-child(3) {
                    textarea {
                        border-color: #cdcdcd;
                        width: 239px;
                        height: 109px; } } } } }

    #album_box {
        .sub_box_title {
            padding-right: 7.5%; }

        tbody {
            tr {
                td:nth-child(5) {
                    text-align: right;

                    a {
                        display: inline-block; }

                    form {
                        width: auto;
                        display: inline-block; } } } } }

    #video_box {
        .sub_box_title {
            padding-right: 6.4%; }

        .order_text {
            text-align: right;
            padding-right: 75px; }

        tbody {
            td:nth-child(4) {
                padding: 45px 0px 0px 0px;
                text-align: right;

                input {
                    display: inline-block; }

                input[type='number'] {
                    width: 54px;
                    margin-right: 4px; } }

            td:nth-child(5) {
                padding-top: 45px; }

            td:nth-child(6) {
                border-bottom-color: #ffffff;
                border-top-color: #ffffff; } } } }
