.backend-container {
    .check_radio {
        div {
            display: inline-block; }

        .check_input {
            width: calc(100% - 95px);

            input {
                width: 100%; } } }

    #speaker_box {
        #speaker_title {
            .row {
                padding-bottom: 10px;
                line-height: 38px;
                height: 50px;
                position: relative;
                margin-top: 10px;

                div, input, h5 {
                    display: inline-block; }

                h5 {
                    margin-left: 15px; }

                input[name="title"], .search_box {
                    border-radius: 4px;
                    height: 38px;
                    border: 1px solid #cdcdcd; }

                input[name='title'] {
                    width: 400px;
                    padding-left: 10px; }

                input[name='search'] {
                    height: 28px;
                    border: 0px; }

                .search_box {
                    width: 226px;
                    line-height: 38px;

                    input[name='search'] {
                        width: 180px;
                        padding-left: 10px; }

                    span {
                        display: inline-block;
                        width: 38px;
                        line-height: 38px;
                        height: 38px;
                        text-align: center; } }

                .non-table-create-btn-box {
                    position: absolute;
                    right: 5.9%;
                    top: 5px;
                    padding-top: 0; } } }

        #speaker_table {
            thead {
                border-radius: 4px; }

            td {
                line-height: 100px; }

            td:nth-child(1) {
                div {
                    width: 90px;
                    height: 100px;
                    background-color: #666666; } }

            td:nth-child(5) {
                text-align: right;

                a {
                    display: inline-block; } } } }

    .radio_box {
        line-height: 34px;
        margin-right: 10px; } }


