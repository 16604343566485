#bootstrapoverride .attendees-container {
	.hero-bg {
		@include image-formating("../../../assets/img/attendees/img-attendees-hero.png"); }


	section.forum h2 {
		margin-bottom: 130px; }


	section.tickets-info {
		max-width: 800px;

		img {
			width: 80px;
			height: 80px;
			margin-right: 20px; }

		p {
			display: inline-block;
			width: calc(100% - 110px);
			font-family: $secondary-style; }


		.gradient-btn {
			display: block;
			width: 210px;
			margin: 40px auto;
			padding: 10px; } } }


@media all and (max-width: $tablet) {
	#bootstrapoverride .attendees-container {
		section.forum {
			padding: 0 15px; } } }


@media all and (max-width: $mobile) {
	#bootstrapoverride .attendees-container {
		p {
			font-size: 14px;
			line-height: 24px; }


		section.tickets-info {
			.flexbox-wrapper {
				align-items: flex-start; }

			img {
				width: 50px;
				height: 50px; }

			p {
				display: inline-block;
				width: calc(100% - 70px); } }


		section.forum h2 {
			margin-bottom: 110px; } } }

#bootstrapoverride {
	#exhibitor-list > div {
		cursor: pointer; }

	.modal {
		overflow: hidden; }

	.modal-header {
		border: none;
		padding-top: 0;
		@media (max-width: $mobile) {
			padding: 0 15px; } }

	.modal-footer {
		border: none; }

	.modal-content {
		border-radius: 0;
		background-color: #eeeeee;
		text-align: left;
		height: 85vh;
		top: 5vh;

		#name {
			color: #300094;
			font-weight: 700;
			font-size: 18px;

			a {
				color: #300094;
				font-weight: 700;
				font-size: 18px;
				text-decoration: underline; }

			span {
				float: right; } }

		.category {
			font-style: italic; }

		#booth {
			font-weight: bold;
			font-size: 12px; }

		hr {
			border-color: #e0e0e0; }

		button {
			opacity: 1;

			span {
				font-size: 50px;
				color: #232323;
				font-weight: 100;
				opacity: 1;
				font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
				-webkit-font-smoothing: antialiased;
				line-height: inherit; } } }

	.modal-dialog {
		@media (min-width: $mobile) {
			width: 850px; } }

	.modal-body {
		padding: 0 65px;
		height: 650px;

		&::-webkit-scrollbar {
			display: none; }

		div {
			margin-bottom: 10px; }

		@media (max-width: $mobile) {
			height: 75vh;
			padding: 0 15px; } }

	.product-info {
		margin: 30px 0 5px 0; }

	.album-img-box {

		padding-left: 0;

		img {
			vertical-align: middle;
			height: 130px;
			object-fit: contain; } } }










