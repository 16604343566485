.inline {
	display: inline;
}
.head-block {
	width: 100%;
	height: 150px;
}
.backend-container {
	/* max-width: 1180px; */
}
.backend-container tbody .btn {
	max-width: 140px;
    max-height: 50px;
}
.backend-container tbody form .btn {
    /*padding-top: 3px;*/
}
.news-edit-box {
	width: 822px;
} 
.non-table-create-btn-box {
	text-align: right; 
	padding: 20px 15px 0 0;
}
.album-box {
	padding: 10px;
	text-align: center;
}
.album-box a{
	color: black;
	text-decoration: none;
}
.album-box a p {
	font-size: 18px;
}
.album-box a img {
	width: 100%;
}
.album-img-box {
	width: 90%;
}
.album-img-box img{
	width: 100%;
	height: auto;
}
.tab-form-box {
	padding: 20px 0;
}