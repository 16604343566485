$b-height: 1.15em;
$el-size: 1vh;
$DNA_scale: 0.5; //divides '$el-size'
$t: 2.1s;
$td: -0.89;

$c_a: rgb(255, 132, 144) rgb(255, 115, 124) rgb(245, 247, 249); // top / bottom / BG
$c2_a: rgb(255, 218, 178) rgb(255, 50, 155); // Fade-anim colors

$ease-circ: cubic-bezier(0.42 , 0, 0.58, 1);


.DNA_cont {
	display: inline-block;
	position: relative;
	transform: scale($DNA_scale); }


.nucleobase {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	//  width: 1px; height: $el-size*2.75
	//  background: transparent
	//  box-shadow: $el-size*0.475 $el-size*-0.85 0 rgba(0,0,0,0.2)

	&:not(:last-child) {
		margin-right: $el-size*1.62; }


	&:before, &:after {
	    content: "";
	    display: inline-block;
	    width: $el-size;
	    height: $el-size;
	    border-radius: 50%;
	    position: absolute; }


	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			// animation: animDotBar $t $ease-circ infinite
			animation-delay: $i*($td*$t);

			&:before {
				// transform: translateY(-200%)
				animation: animBefore $t $ease-circ infinite;
				animation-delay: $i*($td*$t);
				background-color: nth($c_a, 1); }


			&:after {
				// transform: translateY(200%)
				animation: animAfter $t $ease-circ infinite;
				animation-delay: $i*($td*$t);
				background-color: nth($c_a, 2);
 } } } }				//  mix-blend-mode: multiply


@keyframes animBefore {
	0% {
	    top: $el-size *-2;
	    z-index: 1; }

	25% {
		transform: scale(1.2) {
	    z-index: 1; } }

	50% {
		top: $el-size *2;
		z-index: -1; }

	75% {
		background-color: nth($c2_a, 1);
		transform: scale(0.8);
		z-index: -1; }

	100% {
		top: $el-size *-2;
		z-index: -1; } }


@keyframes animAfter {
	0% {
	    top: $el-size *2;
	    z-index: -1; }

	25% {
	    background-color: nth($c2_a, 2);
	    transform: scale(0.8);
	    z-index: -1; }

	50% {
	    top: $el-size *-2;
	    z-index: 1; }

	75% {
	    transform: scale(1.2);
	    z-index: 1; }

	100% {
	    top: $el-size *2;
	    z-index: 1; } }



.loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	padding-top: 42vh;
	text-align: center;
	background-color: white;
	z-index: 9999;
	pointer-events: none; }






