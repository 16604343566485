#bootstrapoverride {
	.bg-mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(9, 15, 24, .8);
		z-index: 9998;
		opacity: 0;
		pointer-events: none;
		transition: opacity .3s;

		&.active {
			opacity: 1;
			pointer-events: auto; } }


	.modal-white {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 90vw;
		height: 80vh;
		background-color: white;
		margin: 10vh auto;
		box-shadow: $box-shadow;
		text-align: center;
		z-index: 9999;

		&.active {
			display: flex;
			align-items: center;
			justify-content: center; }

		.x-btn {
			@include x-btn(25px, 1px, #4a4a4a);
			position: absolute;
			top: 25px;
			right: 25px; }

		&.floor-plan-window img {
			height: auto;
			max-height: 70vh;
			object-fit: contain; } }


	.hero-container {
		position: relative;
		margin-bottom: 50px;

		.hero-bg {
			position: relative;
			width: 100%;
			height: 390px;
			overflow: hidden;
			clip-path: polygon(0 0, 1920px 0, 1920px 55%, 0 100%); }

		.hero-mask {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 390px;
			opacity: .6;
			background: linear-gradient(to right, #a159c9 0%, #3023ae 25%, #3721ac 50%, #e0007a 75%, #e92f64 100%);
			clip-path: polygon(0 0, 1920px 0, 1920px 55%, 0 100%); }

		.hero-heading {
			position: absolute;
			top: 190px;
			left: 0;
			right: 0;
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
			font-size: 40px;
			line-height: 57px;
			font-style: italic;
			font-weight: bold;
			text-align: center;
			color: $yellow; } }



	.yellow-skew {
		position: relative;
		font-weight: bold;
		font-style: italic;
		font-size: 30px;
		line-height: 41px;
		margin-bottom: 65px;

		&:after {
			content: "";
			position: absolute;
			bottom: -35px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 30px;
			height: 12px;
			background-color: #FECD19;
			transform: rotate(-20deg) skew(-20deg, 0deg); } }


	.yellow-skew-before {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: -15px;
			width: 15px;
			height: 4px;
			background-color: #FECD19;
			transform: rotate(-20deg) skew(-20deg, 0deg); } }


	.btn {
		border-radius: 0; }


	.gradient-btn {
		@include gradient-border(3px, right);
		padding: 8px 40px;
		color: $blue;
		margin-top: 15px;
		font-size: 18px;
		font-weight: 600;
		font-style: italic;
		background-color: white;
		text-align: center;
		transition: border .2s, background-color .2s, color .2s;

		&:focus,
		&:active,
		&:hover {
			border: solid 3px #e20b80;
			background-color: #e20b80;
			color: white; } }


	.pink-btn {
		padding: 10px 70px;
		color: white;
		font-size: 18px;
		font-weight: 600;
		font-style: italic;
		background-color: $pink; }


	.subscribe-success-modal {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,.8);
		z-index: 9999;

		.inner-wrapper {
			position: relative;
			width: 500px;
			height: 360px;
			margin: calc(50vh - 110px) auto;
			padding: 60px;
			text-align: center;
			background-color: #eeeeee; }

		.x-btn {
			@include x-btn(25px, 1px, #2a2a2a);
			position: absolute;
			top: 25px;
			right: 25px; }

		h1 {
			font-size: 30px;
			line-height: 41px;
			font-weight: 800;
			color: #e0007a;
			margin: 0 0 12px 0;
			text-shadow: -9px 6px 0 rgba(224, 0, 121, 0.2); }

		p {
			line-height: 20px; } }


	.login-modal-container {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,.8);
		z-index: 9999;

		.inner-wrapper {
			position: relative;
			width: 980px;
			height: 650px;
			margin: calc((100vh - 650px) / 2) auto;
			padding: 60px;
			text-align: center;
			background-color: #eeeeee;

			p,
			a {
				font-family: $secondary-style;
				font-size: 18px;
				line-height: 21px;
				font-weight: normal;
				font-style: normal; }

			a {
				text-decoration: underline; }

			.btn {
				width: 240px;
				margin-bottom: 10px; } }

		.yellow-skew {
			font-size: 30px;
			line-height: 57px;

			&:after {
				bottom: -25px;
				left: 0;
				right: 0;
				margin: 0 auto; } }

		.x-btn {
			@include x-btn(25px, 1px, #2a2a2a);
			position: absolute;
			top: 25px;
			right: 25px; } }



	section.subscribe-well {
		width: 100vw;
		max-width: 1920px;
		height: 50px;
		margin-bottom: 100px;
		transform: translate3d(100vw, 0, 0);
		transition: transform 1s cubic-bezier(.39,.21,.35,1.15);

		&.slide-in-view {
			transform: translate3d(0, 0, 0); }

		.inner-container {
			display: flex;
			align-items: center;
			width: 800px;
			margin: 0 auto; }

		form {
			margin: 0; }

		h4 {
			display: inline-block;
			font-size: 30px;
			line-height: 32px;
			color: #4a4a4a;
			font-weight: bold;
			font-style: italic;
			margin-right: 30px; }

		.input-wrapper {
			display: inline-block;
			position: relative;
			width: 570px;
			border-radius: 4px;

			input {
				width: 450px;
				height: 48px;
				border-radius: 4px;
				border: solid 1px #e0e0e0;
				padding-left: 15px; }

			button {
				position: absolute;
				right: 0;
				top: -6px;
				width: 155px;
				height: 58px;
				border-radius: 4px;
				border: none;
				background-color: #e0007a;
				color: white;
				box-shadow: $box-shadow2;
				font-size: 18px;
				line-height: 25px;
				font-weight: bold;
				font-style: italic; } } }



	.file-download {
		text-align: center;

		a {
			position: relative;
			display: inline-block;

			&:after {
				content: "Download";
				position: absolute;
				top: 45px;
				left: 0;
				width: 100%;
				text-align: center;
				color: $pink;
				font-size: 18px;
				font-weight: bold;
				font-style: italic;
				opacity: 0;
				pointer-events: none;
				transition: opacity .2s, transform .2s; }

			&:hover img {
				opacity: .4; }

			&:hover:after {
				opacity: 1;
				transform: translateY(-10px); } }

		img {
			transition: opacity .2s; }

		p {
			font-weight: bold;
			font-style: italic; } }




	span.btn-arrow-left,
	span.btn-arrow-right {
		display: inline-block;
		position: absolute;
		top: calc(50% - 20px);
		width: 25px;
		height: 40px;
		z-index: 10; }

	span.btn-arrow-left {
		@include image-formating("../../../assets/img/icons/btn-left.png"); }
	span.btn-arrow-right {
		@include image-formating("../../../assets/img/icons/btn-right.png"); }


	span.btn-arrow-left-mobile,
	span.btn-arrow-right-mobile {
		display: inline-block;
		position: absolute;
		top: calc(50% - 20px);
		width: 70px;
		height: 70px;
		z-index: 10;
		border-radius: 50%;
		background-color: rgba(0,0,0,.7); }

	span.btn-arrow-left-mobile:after {
		content: url("../../../assets/img/icons/btn-angle-right.svg");
		position: absolute;
		top: 20px;
		left: 46px;
		transform: rotate(180deg); }

	span.btn-arrow-right-mobile:after {
		content: url("../../../assets/img/icons/btn-angle-right.svg");
		position: absolute;
		top: 25px;
		right: 46px; }



	.pagination {
		position: relative;
		display: block;
		width: 250px;
		margin: 60px auto 20px;

		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 60px; }

		li {
			display: inline-block;
			margin: 0 auto;
			font-size: 24px;
			line-height: 33px;
			font-weight: bold;
			font-family: $secondary-style;
			color: #545274;


			&:hover {
				cursor: pointer; }

			&.active {
				color: $pink; } }


		.btn-arrow-left {
			left: 0; }
		.btn-arrow-right {
			right: 0; } } }




@media all and (max-width: $tablet) {
	#bootstrapoverride {
		.hero-container {
			.hero-bg {
				height: 290px;
				clip-path: polygon(0 0, 1200px 0, 1200px 65%, 0 100%); }

			.hero-mask {
				height: 290px;
				clip-path: polygon(0 0, 1200px 0, 1200px 65%, 0 100%); }

			.hero-heading {
				top: 100px; } }


		.yellow-skew {
			&:after {
				width: 25px;
				height: 10px; } }


		.news-section {
			padding: 0 15px;

			h3 {
				margin-bottom: 80px; }

			h4 {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 20px; } }


		section.subscribe-well {
			.inner-container {
				width: 700px; }

			h4 {
				font-size: 24px;
				line-height: 32px; }

			.input-wrapper {
				width: 510px; } }


		.login-modal-container {
			.inner-wrapper {
				width: 100%; } } } }




@media all and (max-width: $mobile) {
	#bootstrapoverride {
		.hero-container {
			.hero-bg {
				height: 145px;
				clip-path: polygon(0 0, 767px 0, 767px 55%, 0 100%); }

			.hero-mask {
				height: 145px;
				clip-path: polygon(0 0, 767px 0, 767px 55%, 0 100%); }

			.hero-heading {
				top: 55px;
				font-size: 24px;
				line-height: 33px; } }


		.yellow-skew {
			margin: 0 auto 45px;

			&:after {
				bottom: -25px;
				width: 20px;
				height: 7px; } }


		section.subscribe-well {
			height: 90px;
			background-color: white;
			padding: 0 15px;

			.inner-container {
				display: block;
				width: 100%; }

			h4 {
				display: block;
				width: 100%;
				font-size: 18px;
				text-align: center;
				margin-bottom: 10px; }

			.input-wrapper {
				display: block;
				width: 100%;
				box-shadow: none;

				input {
					width: 100%; }

				button {
					width: 60px;
					height: 60px;
					border-radius: 4px;
					box-shadow: $box-shadow2;
					font-size: 12px;
					line-height: 15px; } } }


		.subscribe-success-modal {
			.inner-wrapper {
				width: 100%;
				max-width: 500px;
				padding: 50px 20px; }

			.x-btn {
				top: 15px;
				right: 15px; } }


		.login-modal-container {
			.inner-wrapper {
				height: 100vh;
				max-height: 480px;
				margin: calc((100vh - 480px) / 2) auto; }

			form {
				margin: 80px auto; }

			.btn {
				width: 240px;
				margin-bottom: 10px;
				margin-top: 0; }

			.yellow-skew {
				font-size: 20px;
				line-height: 27px;

				&:after {
					bottom: -25px;
					left: 0;
					right: 0;
					margin: 0 auto; } } } } }












