#bootstrapoverride .search-container {
  .hero-bg {
    @include image-formating("../../../assets/img/events/img-event-hero.png"); }


  section.result-section {
    max-width: 980px;

    .search-title, .result-snippet b {
      font-size: 18px;
      color: #251c99;
      font-weight: bold; }

    p {
      font-size: 14px; } } }
