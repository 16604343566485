.backend-container {
    #agenda_title {
        width: 100%;
        height: 80px;
        line-height: 80px;
        position: relative;

        h5, div, input[type="text"] {
            display: inline-block;
            height: 38px; }

        input[type="text"] {
            width: 400px;
            border: 1px solid #cdcdcd;
            padding-left: 10px;
            border-radius: 4px;
            margin-left: 10px; }

        div {
            position: absolute;
            right: 6.2%;

            span {
                color: #4a90e2;
                margin-right: 5px; } } }

    #agenda_box {
        padding-left: 30px;

        ul[role="tablist"] {
            li {
                border-radius: 4px;
                margin-right: 2px;
                background-color: #efefef; } }

        .select_date_box {
            margin-top: -5px;

            h5, input {
                display: inline-block;
                height: 38px; }

            input {
                width: 400px;
                padding-left: 10px;
                border-radius: 4px;
                margin-left: 10px;
                border: 1px solid #cdcdcd; } }

        #agenda_table {
            width: 94%;
            border-radius: 4px;

            tr {
                height: 40px;
                color: #505050;
                background-color: #efefef;

                th:nth-child(1) {
                    padding-left: 30px; }

                th:nth-child(2), th:nth-child(3) {
                    padding-left: 0; } } }

        #agenda_sub_table {
            border: 1px solid #cdcdcd;
            width: 94%;
            height: 700px;
            overflow: scroll; }

        #time_col {
            text-align: center;
            padding-left: 25px;
            height: 200px;

            input {
                width: 64px;
                height: 38px;
                padding-left: 10px;
                border-radius: 4px;
                background-color: #ffffff;
                border: solid 1px #cdcdcd; }

            div {
                    height: 140px;
                    width: 0;
                    border: 1px solid #cdcdcd;
                    margin: 5px auto; } }

        .content_col {
            input, div {
                margin-top: 4px; } } } }



