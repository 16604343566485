.content {
    padding-left: 0;
    padding-right: 0;
    width: calc(100% - 200px); }

.skyblue_btn {
    background-color: #26c5cf;
    border-color: #26c5cf; }

.lightgreen_btn {
    background-color: #05ca05;
    border-color: #05ca05; }

.orange_btn {
    background-color: #eba525;
    border-color: #eba525; }

.delete_btn {
    &:hover {
        background-color: #e30213;
        border-color: #e30213;
        color: #ffffff; } }

.blue_btn {
    background-color: #1890ff;
    border-color: #1890ff; }

.no_nav_content {
    width: 100%; }

.box_title {
    background-color: #e0e4e8;
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 20px;
    color: #505050;
    padding-left: 30px; }

.blue_title {
    background-color: #3f5685; }

.blue_word {
    color: #4a90e2; }

.backend-container {
    margin: 0px;
    width: 100%;
    padding-bottom: 90px;

    h5 {
        margin-top: 20px; }

    h5, .general_box {
        margin-left: 30px; }

    #date_box, #location_box, #floor_box, #register_box {
        padding-bottom: 60px; }

    #date_box {
        .general_box {
            position: relative;

            div {
                display: inline-block; }

            div:last-child {
                width: 400px;
                position: absolute;
                margin-left: 10px; } } }

    .general_box {
        input {
            height: 38px;
            border-radius: 4px;
            padding-left: 10px; }

        input[type="text"] {
            border: 1px solid #cdcdcd;
            width: 300px; }

        input[value="更新"] {
            width: 58px;
            color: #ffffff;
            background-color: #05ca05; }

        span:last-child {
            color: #919191;
            margin-top: 5px; } }



    .middle_box {
        input[type="text"] {
            width: 600px; } }

    .long_box {
        input[type="text"] {
            width: 800px; } } }
