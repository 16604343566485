
#bootstrapoverride {
	h1, h2, h3, h4, h5, li, a, label, p, span, div {
		font-family: $primary-style;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 22px;
		color: $text-gray; }


	text {
		font-family: $primary-style; }


	li, a, label, p, span, div {
		font-size: 14px; }


	h1, h2, h3, h4, h5, p {
		margin: 0; }


	h1 {
		font-size: 45px; }
	h2 {
		font-size: 37px;
		line-height: 45px; }
	h3 {
		font-size: 24px;
		line-height: 32px; }
	h4 {
		font-size: 20px;
		line-height: 28px; }
	h5 {
		font-size: 15px; }


	p {
		font-size: 14px;
		line-height: 24px;
		color: $text-gray; }



	input, select, label {
		color: $text-gray; }

	a {
		text-decoration: none; }


	input:focus,
	textarea:focus {
		outline: none; }


	.img-center {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover; } }


	.fa {
		font-family: "fa"; }


	.bold {
		font-weight: bold; }


	.clearfix:after {
		content: "";
		clear: both;
		display: table; }


	// classes for 5 column layout
	.col-xs-15,
	.col-sm-15,
	.col-md-15,
	.col-lg-15 {
	    position: relative;
	    min-height: 1px;
	    padding-right: 10px;
	    padding-left: 10px; }


	.col-xs-15 {
	    width: 20%;
	    float: left; }

	@media (min-width: 768px) {
		.col-sm-15 {
	        width: 20%;
	        float: left; } }

	@media (min-width: 992px) {
	    .col-md-15 {
	        width: 20%;
	        float: left; } }

	@media (min-width: 1200px) {
	    .col-lg-15 {
	        width: 20%;
	        float: left; } }

	// classes for 2 column layout
	@media (max-width: 610px) {
		.col-xs-22 {
			width: 50%;
			float: left; } } }













