.backend-container {
    .general_box {
        textarea {
            width: 800px;
            height: 160px;
            border-radius: 4px;
            border: solid 1px #cdcdcd;
            padding-left: 10px; } }

    #home_box, #exhibitor_box, #about_box {
        padding-bottom: 60px;

        .general_box {
            position: relative;

            textarea, input {
                display: inline-block; }

            input[type="submit"] {
                position: absolute;
                margin-left: 10px; } } }

    .file_box {
        color: #505050;

        table {
            width: 950px;

            th:nth-child(1), td:nth-child(1) {
                width: 40px; }

            th:nth-child(2), td:nth-child(2) {
                width: 210px; }

            th:nth-child(3), td:nth-child(3) {
                width: 250px; }

            th:nth-child(4), td:nth-child(4) {
                width: 90px; }

            th:nth-child(5), td:nth-child(5) {
                width: 130px; }

            th:nth-child(6), td:nth-child(6) {
                width: 70px; }

            tr {
                width: 950px;
                height: 59px;
                border-radius: 4px;
                border-bottom: 1px solid #cdcdcd; }

            td {
                input, label {
                    height: 38px;
                    border-radius: 4px;
                    border: solid 1px #cdcdcd;
                    background-color: #ffffff; } }

            tr:first-child {
                height: 40px;
                background-color: #efefef; }

            td:nth-child(6) {
                line-height: 35px; }

            td:nth-child(2) {
                input {
                    width: 200px;
                    box-shadow: 0 0 6px 0 rgba(33, 180, 254, 0.4);
                    padding-left: 10px; } }

            td:nth-child(3) {
                label {
                    width: 102px;
                    line-height: 38px;
                    padding-left: 12px;
                    color: #919191; } } } } }

