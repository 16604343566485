#bootstrapoverride {
	p {
		font-size: 18px;
		line-height: 28px; }


	.section-title {
		font-size: 30px;
		line-height: 41px;
		font-weight: bold;
		font-style: italic;
		text-align: center; }


	section {
		max-width: 1200px;
		margin: 0 auto 100px; }



	.flexbox-wrapper {
		display: flex;
		align-items: center; }


	.pink-date {
		height: 21px;
		overflow: hidden;
		font-size: 14px;
		line-height: 19px;
		font-style: italic;
		color: $pink;
		margin-bottom: 4px; }


	.blue-title {
		max-height: 70px;
		font-size: 18px;
		line-height: 23px;
		overflow: hidden;
		font-weight: bold;
		color: $blue; }


	.pink-link {
		color: $pink;
		font-size: 14px;
		line-height: 19px; } }




@media all and (max-width: $tablet) {
	#bootstrapoverride {
		p {
			font-size: 14px;
			line-height: 24px; }


		section {
			padding: 0 15px; } } }





@media all and (max-width: $mobile) {
	#bootstrapoverride {
		p {
			line-height: 19px; }


		.section-title {
			font-size: 20px;
			line-height: 27px; } } }


