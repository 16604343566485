#bootstrapoverride .about-container {
	.hero-bg {
		@include image-formating("../../../assets/img/about/img-about-hero.png"); }


	section.tickets-info {
		max-width: 780px;
		text-align: center;

		p {
			margin-bottom: 55px;
			font-family: $secondary-style; } }


	section.discover-innovex {
		max-width: 1000px;

		.row>div {
			height: 250px;
			margin-bottom: 60px;
			padding: 0 20px;
			text-align: center; }

		.head {
			span {
				font-size: 60px;
				line-height: 82px;
				font-weight: 800;
				margin: 0 0 12px 0; }

			h3 {
				font-size: 25px;
				line-height: 34px;
				margin: 0 0 10px 0;
				font-weight: bold;
				letter-spacing: 3px; }

			&.pink {
				span {
					color: $pink;
					text-shadow: -9px 6px 0 rgba(224, 0, 121, .2); }
				h3 {
					color: $pink; } }
			&.blue {
				span {
					color: $blue;
					text-shadow: -9px 6px 0 rgba(37, 28, 153, .2); }
				h3 {
					color: $blue; } } }

		p {
			font-family: $secondary-style; } }


	section.general-info {
		max-width: 800px;

		.floor-plan-modal {
			cursor: pointer; }

		.row>div:not(:last-child) {
			margin-bottom: 30px;

			p {
				margin: 0;
				font-family: $secondary-style; } }

		img {
			margin-right: 15px; } }


	section.transportation {
		.map-container {
			width: 100%;
			height: 470px;
			max-width: 980px;
			margin: 0 auto;

			iframe {
				height: 470px; } } }


	section.sponsors {
		max-width: 980px;

		.row {
			margin-bottom: 80px;

			&>div {
				margin-bottom: 30px; } }

		.row-title {
			font-size: 24px;
			line-height: 33px;
			color: $blue;
			font-weight: bold;
			font-style: italic;
			margin-bottom: 20px; }

		.img-center img {
			height: 110px;
			object-fit: contain; } } }



@media all and (max-width: $mobile) {
	#bootstrapoverride .about-container {
		section.discover-innovex {
			.row>div {
				height: auto;
				margin-bottom: 40px; } }


		section.general-info {
			.flexbox-wrapper {
				margin-bottom: 20px; }

			img {
				width: 50px;
				height: 50px; } }


		section.transportation {
			padding: 0;

			.map-container {
				width: 100%;
				height: 355px;

				iframe {
					height: 355px; } } }


		section.sponsors {
			.row {
				margin-bottom: 10px;
				padding: 0 10px;

				&>div {
					margin-bottom: 20px;
					padding: 0 5px;

					img {
						height: 85px; } } }

			.row-title {
				font-size: 18px;
				line-height: 28px;
				margin-bottom: 15px; } } } }













