#bootstrapoverride .exhibitor-container {
	.hero-bg {
		@include image-formating("../../../assets/img/exhibitor/img-exhibitor-hero.png"); }

	.anchor {
		display: block;
		position: relative;
		top: -80px; }

	#copy-activity {
		display: inline-block;
		margin-left: 10px;
		width: 30px;
		height: 30px;
		border: none;
		background-color: transparent;
		background-image: url("../../../assets/img/icons/icon-share.svg");
		background-size: contain;
		background-position: -5px 3px;
		background-repeat: no-repeat;
		outline: none;
		transition: all .2s;

		&:hover {
			background-image: url("../../../assets/img/icons/icon-share-hover.svg"); } }

	section.who {
		max-width: 780px;
		text-align: center;

		p {
			margin-bottom: 30px;
			font-family: $secondary-style; } }


	section.activity {
		max-width: 900px; }

	section.exhibit-regulation {
		max-width: 980px;

		h3 {
			font-size: 24px;
			line-height: 33px;
			color: $blue;
			font-weight: bold;
			font-style: italic;
			margin: 70px 0 10px; }

		.remark {
			font-size: 14px;
			line-height: 19px;
			font-style: italic;
			color: $pink;
			margin-bottom: 25px; }

		.registration-procedure {
			position: relative;

			p {
				display: inline-block;
				float: left;
				margin-bottom: 30px; }

			.desc {
				clear: left;
				width: 220px;
				margin-right: 80px;
				text-align: right;
				font-weight: bold;
				font-style: italic; }

			.info {
				width: calc(100% - 310px);
				font-family: $secondary-style; } }


		.color-steps {
			position: absolute;
			left: 250px;

			span {
				position: absolute;
				left: 0;
				display: inline-block;
				width: 16px;
				height: 16px;
				border-radius: 50%;

				&:nth-of-type(1):after {
					content: "";
					position: absolute;
					top: 16px;
					left: 7px;
					width: 2px;
					height: 245px;
					background-color: #cccccc; }

				&:nth-of-type(1) {
					top: 10px;
					background-color: #a159c9; }

				&:nth-of-type(2) {
					top: 95px;
					background-color: #251c99; }

				&:nth-of-type(3) {
					top: 180px;
					background-color: #73169b; }

				&:nth-of-type(4) {
					top: 265px;
					background-color: #e0007a; } } }


		table.payment {
			width: 100%;
			font-size: 18px;
			line-height: 28px;

			tr:nth-of-type(odd) {
				background-color: #eeeeee; }

			td {
				padding: 6px 20px; } }

		table tr:last-child td:first-child {
		    border-bottom-left-radius: 4px; }

		table tr:last-child td:last-child {
		    border-bottom-right-radius: 4px; }


		.table-container {
			border: solid 1px #eeeeee;
			border-radius: 4px;
			overflow: hidden;
			margin: 30px 0; }


		.flex-bottom-align {
			display: flex;
			align-items: flex-end;
			margin-top: 30px;

			&>div {
				flex-shrink: 0; }

			.img-container {
				margin-right: 20px;

				p {
					display: inline;
					font-weight: bold;
					font-style: italic;
					color: $pink; } }

			.img-center img {
				height: 375px; }

			.table-container {
				width: calc(100% - 500px);
				margin: 0; }

			table {
				height: 373px;
				line-height: 20px;

				thead {
					background-color: #eeeeee;
					color: #4a4a4a;
					font-size: 18px;
					line-height: 28px; }

				tr {
					border-bottom: solid 1px #eeeeee;

					&:last-child {
						border-bottom: none; } }

				th {
					padding: 5px 20px;
					font-weight: normal; }

				td {
					padding: 2px 20px; } } }


		ol li {
			font-size: 18px;
			line-height: 30px; } } }





@media all and (max-width: $tablet) {
	#bootstrapoverride .exhibitor-container {
		section.exhibit-regulation {
			padding: 0 15px;

			.registration-procedure {
				.desc {
					width: 150px;
					font-size: 14px; }

				.info {
					width: calc(100% - 230px);
					font-size: 14px; } }


			.color-steps {
				left: 180px; }



			.flex-bottom-align {
				display: block;

				.img-container {
					max-width: 480px;
					margin: 0 auto 40px; }


				.table-container {
					width: 100%;
					max-width: 480px;
					margin: 0 auto 60px; } } }


		section.activity .row>div {
			margin-bottom: 40px; } } }




@media all and (max-width: $lg) {
	#bootstrapoverride .exhibitor-container {
		section.activity {
			max-width: 400px; } } }




@media all and (max-width: $mobile) {
	#bootstrapoverride .exhibitor-container {
		#copy-activity {
			margin-left: 5px;
			width: 25px;
			height: 25px;
			background-position: -4px 2px; }

		section.activity img {
			width: 60px;
			height: 60px; }


		section.exhibit-regulation {
			h3 {
				margin-top: 40px; }

			.registration-procedure {
				p {
					padding-left: 30px; }

				.desc {
					width: 100%;
					margin: 0;
					text-align: left; }

				.info {
					width: 100%;
					height: 95px;
					margin-bottom: 11px; } }


			.color-steps {
				position: absolute;
				left: 0;
				top: -6px;

				span {
					&:nth-of-type(1):after {
						height: 380px; }
					&:nth-of-type(1) {
						top: 10px; }
					&:nth-of-type(2) {
						top: 140px; }
					&:nth-of-type(3) {
						top: 270px; }
					&:nth-of-type(4) {
						top: 400px; } } }


			table.payment {
				font-size: 14px;
				line-height: 24px;

				td {
					padding: 6px 10px; } }

			.table-container {
				margin: 30px 0 15px 0; }


			.flex-bottom-align {
				display: block;

				.img-container {
					margin: 0 auto 30px; }

				.img-center img {
					height: 290px; }

				.table-container {
					width: 100%; }

				table {
					height: auto;
					line-height: 20px;

					thead {
						font-size: 14px;
						line-height: 24px; }

					th {
						padding: 5px 10px;
						font-weight: bold; }

					td {
						padding: 4px 10px; } } }


			ol li {
				font-size: 14px;
				line-height: 24px; } } } }











